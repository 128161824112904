import BeatmatchBackendClient from "BeatmatchBackendClient";
import React from "react";
import { Modal, Button, Form } from 'react-bootstrap';

const ModalContents = Object.freeze({
  Login: 0,
  Signup: 1,
  ForgotPassword: 2,
});

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalContents: ModalContents.Login,
      loginFormInputs: {
        email: "",
        password: ""
      },
      signupFormInputs: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      forgotPasswordFormInputs: {
        email: ""
      },
    };
  }

  loginModalContents = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Login to your account.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={this.state.loginFormInputs.email}
              onChange={(e) => {
                this.setState((prevState) => ({
                  loginFormInputs: {
                    ...prevState.loginFormInputs,
                    email: e.target.value
                  }
                }));
              }}
              autoFocus
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formBasicPassword"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              rows={3}
              value={this.state.loginFormInputs.password}
              onChange={(e) => {
                this.setState((prevState) => ({
                  loginFormInputs: {
                    ...prevState.loginFormInputs,
                    password: e.target.value
                  }
                }));
              }} />
            <div className="d-flex justify-content-end">
              <Button style={{ fontSize: "1em"}} variant="link" onClick={() => {this.setState({ modalContents: ModalContents.ForgotPassword })}}>Forgot Password?</Button>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={this.props.onHide}>Login</Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-center w-100">
        <p className="mb-2">- Don't have an account? -</p>
        <Button variant="primary" onClick={() => {this.setState({ modalContents: ModalContents.Signup })}} className="w-100">
          Create an account
        </Button>
      </Modal.Footer>
    </>
  )

  signupModalContents = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create an account.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={this.state.signupFormInputs.email}
              onChange={(e) => {
                this.setState((prevState) => ({
                  signupFormInputs: {
                    ...prevState.signupFormInputs,
                    email: e.target.value
                  }
                }));
              }}
              autoFocus
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formBasicPassword"
          >
            <Form.Label>Create Password</Form.Label>
            <Form.Control 
              type="password"
              rows={3}
              value={this.state.signupFormInputs.password}
              onChange={(e) => {
                this.setState((prevState) => ({
                  signupFormInputs: {
                    ...prevState.signupFormInputs,
                    password: e.target.value
                  }
                }));
              }} />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formBasicPassword"
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              rows={3}
              value={this.state.signupFormInputs.confirmPassword}
              onChange={(e) => {
                this.setState((prevState) => ({
                  signupFormInputs: {
                    ...prevState.signupFormInputs,
                    confirmPassword: e.target.value
                  }
                }));
              }} />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={async () => { await this.sendSignupRequest(); this.props.onHide();}}>Create Account</Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-center w-100">
        <p className="mb-2">- Already have an account? -</p>
        <Button variant="primary" onClick={() => {this.setState({ modalContents: ModalContents.Login })}} className="w-100">
          Login with existing account
        </Button>
      </Modal.Footer>
    </>
  )

  forgotPasswordModalContents = () => (
    <>
      <Modal.Header className="d-flex flex-column align-items-start">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Modal.Title>Forgot your password?</Modal.Title>
          <button
            type="button"
            className="btn-close "
            onClick={() => {this.props.onHide(); this.setState({modalContents: ModalContents.Login});}}
          ></button>
        </div>
        <Form.Text>
          Enter your email address and we will send you instructions to reset your password.
        </Form.Text>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={this.state.forgotPasswordFormInputs.email}
              onChange={(e) => {
                this.setState((prevState) => ({
                  forgotPasswordFormInputs: {
                    ...prevState.forgotPasswordFormInputs,
                    email: e.target.value
                  }
                }));
              }}
              autoFocus
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {this.setState({modalContents: ModalContents.Login})}}>
            Go Back
        </Button>
        <Button variant="primary" onClick={this.props.onHide}>
            Continue
        </Button>
      </Modal.Footer>
    </>
  )

  sendSignupRequest = async () => {
    const { email, password } = this.state.signupFormInputs;
    await BeatmatchBackendClient.signUp(email, password);
  }

  render() {
    return (
      <Modal size="md" centered style={{ color: "black" }} show={this.props.show} onHide={() => { this.setState({ modalContents: ModalContents.Login }); this.props.onHide(); }}>
        {(() => {
          switch (this.state.modalContents) {
            case ModalContents.Signup:
              return this.signupModalContents();
            case ModalContents.ForgotPassword:
              return this.forgotPasswordModalContents();
            default:
              return this.loginModalContents();
          }
        })()}
      </Modal>
    )
  }
}

export default LoginModal;