import { ApolloClient, InMemoryCache, gql} from "@apollo/client";

const apolloClient = new ApolloClient({
  uri: process.env.NODE_ENV === "development" ? "http://localhost:4000/graphql/" : 'https://09123ewdw4.execute-api.us-east-1.amazonaws.com/dev/graphql/',
  cache: new InMemoryCache()
});

const BeatmatchBackendClient = {
  getTrack: async function (trackId) {
    await apolloClient
      .query({
        query: gql`
          query GetTrack($trackId: ID!) {
            track(id: $trackId) {
              id,
              bpm,
              key,
              waveform
            }
          }
        `,
        variables: {
          trackId: trackId
        }
      });
  },
  signUp: async function (email, password) {
    const response = await apolloClient
      .mutate({
        mutation: gql`
          mutation Signup($request: SignupInput!) {
            signup(request: $request) {
              accessToken
              refreshToken
            }
          }
        `,
        variables: {
          request: {
            email: email,
            password: password,
          }
        }
      });

      if (response.errors) {
        console.error(JSON.stringify(response.errors));
      }

      console.log(JSON.stringify(response.data));
      return response.data;
  }
}

export default BeatmatchBackendClient;